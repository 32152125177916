import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { GSDevTools } from "gsap/GSDevTools";
import { SplitText } from "gsap/SplitText";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { MotionPathHelper } from "gsap/MotionPathHelper";
// import { CustomEase } from "gsap/CustomEase";
import { $, $$, scaleClip, spOnly, oneLineSplit } from "../_global.js";
import Splide from "@splidejs/splide";

gsap.registerPlugin(
  ScrollTrigger,
  // DrawSVGPlugin,
  ScrollToPlugin,
  // GSDevTools,
  SplitText
  // MotionPathPlugin,
  // MotionPathHelper,
  // CustomEase
);

export default class App {
  constructor() {}
  init() {
    this.opening();
    this.firstDirection();
    this.aboutDirection();
    this.favoriteDirection();
    this.plansDirection();
    this.galleryDirection();
    this.faqDirection();
    // const titleSplit = new SplitText(".first__title__text", {
    //   type: "chars",
    //   // tag: "span",
    // });
    // this.firstTitleGradient(titleSplit);
  }
  opening() {
    if ($("[opening]") === null) return;
    // set
    gsap.set(".about", {
      opacity: 0,
    });

    gsap.set(".base-header", {
      y: "-100%",
    });
    gsap.set(".first__belt", {
      clipPath: "inset(0 0 100% 0)",
    });
    gsap.set(".first__belt span, .first__button", {
      opacity: 0,
    });
    gsap.set(".first__slide__column", {
      clipPath: "inset(3%)",
    });
    gsap.set(".first__slide__item", {
      rotate: "4deg",
      scale: 1.05,
      opacity: 0,
    });
    const titleSplit = new SplitText(".first__title__text", {
      type: "chars",
      // tag: "span",
    });
    gsap.set(titleSplit.chars, {
      y: "20%",
      opacity: 0,
    });
    if (spOnly) {
      gsap.set(".parts-nav", {
        y: "120%",
      });

      gsap.set(".first__title__tips", {
        opacity: 0,
      });
    }
    // play
    const tl = gsap.timeline({
      // paused: true,
    });
    tl.set("[opening]", {
      opacity: 1,
      delay: 2,
    });
    // tl.set(window, {
    //   scrollTo: ".first",
    // });
    tl.to(
      ".first__slide__item",
      {
        rotate: "0deg",
        scale: 1,
        opacity: 1,
        duration: 1.4,
        stagger: 0.1,
      },
      "show"
    );

    tl.to(
      ".first__slide__column",
      {
        clipPath: "inset(0%)",
        duration: 1,
        stagger: 0.1,
        ease: "power3.out",
      },
      "show"
    );

    tl.to(".base-header", {
      y: "0%",
      duration: 0.7,
    });
    tl.to(
      ".first__belt",
      {
        clipPath: "inset(0 0 0% 0)",
        duration: 0.5,
      },
      "-=0.3"
    );
    tl.to(
      ".first__belt span, .first__button",
      {
        opacity: 1,
        duration: 0.5,
        stagger: 0.05,
        clearProps: "all",
      },
      "last"
    );
    tl.to(
      titleSplit.chars,
      {
        y: "0%",
        opacity: 1,
        duration: 1,
        stagger: 0.04,
        ease: "power3.out",
      },
      "last"
    );
    tl.call(() => {
      this.firstTitleGradient(titleSplit);
    });
    tl.to(".first__title__tips", {
      opacity: 1,
      duration: 1,
    });
    tl.to(
      ".parts-nav",
      {
        y: 0,
        duration: 0.8,
      },
      "sp_last"
    );
    tl.to(
      ".about",
      {
        opacity: 1,
        duration: 1,
      },
      "sp_last"
    );
  }
  firstTitleGradient(titleSplit) {
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 10,
    });
    tl.to(".first__title__text div", {
      startAt: {
        "--parcent-x": "-60%",
        "--parcent-y": "0%",
      },
      "--parcent-x": "180%",
      "--parcent-y": "100%",
      duration: 0.8,
      stagger: {
        each: 0.08,
        ease: "linear",
      },
    });
  }
  firstDirection() {
    // beltのマーキー
    if (spOnly) {
      const padding = gsap.getProperty($(".first__belt"), "padding-left");
      const move = $$(".first__belt span")[1].getBoundingClientRect().x - 22 - 5;
      gsap.to(".first__belt span", {
        x: -move,
        duration: 20,
        repeat: -1,
        ease: "none",
      });
    }
  }
  aboutDirection() {
    gsap.to(".about__circle", {
      rotate: "360deg",
      duration: 20,
      repeat: -1,
      ease: "none",
    });

    // first timeline
    const aboutMessagePC = new oneLineSplit({
      target: $(".about__message[pc-only]"),
    });

    const aboutMessageSP = new oneLineSplit({
      target: $(".about__message[sp-only]"),
    });

    // gsap.set(".about__title",{
    //   opacity: 0,
    // })

    const firstAboutEl = $(".about__section:first-child");
    const firstTl = gsap.timeline();
    gsap.set($(".about__title", firstAboutEl), {
      opacity: 0,
    });
    gsap.set($$(".about__message .split-text"), {
      y: 20,
      opacity: 0,
      display: "inline-block",
    });
    gsap.set($(".about__image__contents", firstAboutEl), {
      opacity: 0,
    });
    gsap.set($$(".about__image__shadow", firstAboutEl), {
      opacity: 0,
    });

    if (!spOnly) {
      firstTl.to($(".about__image__contents", firstAboutEl), {
        opacity: 1,
        duration: 1,
      });
      firstTl.to(
        $$(".about__image__shadow", firstAboutEl),
        {
          opacity: 1,
          duration: 1,
        },
        "-=0.5"
      );
    }

    firstTl.to(
      $(".about__title", firstAboutEl),
      {
        opacity: 1,
        duration: 1,
      },
      spOnly ? "" : "-=1"
    );
    firstTl.to(
      $$(".about__message .split-text", firstAboutEl),
      {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.1,
        ease: "power3.out",
      },
      "-=0.8"
    );

    if (spOnly) {
      firstTl.to(
        $(".about__image__contents", firstAboutEl),
        {
          opacity: 1,
          duration: 1.5,
        },
        "-=0.5"
      );
      firstTl.to(
        $$(".about__image__shadow", firstAboutEl),
        {
          opacity: 1,
          duration: 1,
        },
        "-=1"
      );
    }

    firstTl.call(() => {
      aboutMessagePC.kill();
      aboutMessageSP.kill();
    });
    ScrollTrigger.create({
      trigger: firstAboutEl,
      animation: firstTl,
      start: "top center",
    });

    // second timeline
    const secondAboutEl = $(".about__section:nth-child(2)");
    const secondTl = gsap.timeline();
    gsap.set($(".about__title", secondAboutEl), {
      opacity: 0,
    });
    gsap.set($(".about__image__contents", secondAboutEl), {
      opacity: 0,
    });
    gsap.set($$(".about__image__shadow", secondAboutEl), {
      opacity: 0,
    });
    gsap.set($(".about__title", secondAboutEl), {
      opacity: 0,
    });
    gsap.set($$(".about__message__text", secondAboutEl), {
      y: 20,
      opacity: 0,
    });

    if (!spOnly) {
      secondTl.to($(".about__image__contents", secondAboutEl), {
        opacity: 1,
        duration: 1,
      });
      secondTl.to(
        $$(".about__image__shadow", secondAboutEl),
        {
          opacity: 1,
          duration: 1,
        },
        "-=0.5"
      );
    }

    secondTl.to(
      $(".about__title", secondAboutEl),
      {
        opacity: 1,
        duration: 1,
      },
      spOnly ? "" : "-=1"
    );
    secondTl.to(
      $$(".about__message__text", secondAboutEl),
      {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.1,
        ease: "power3.out",
      },
      "-=0.8"
    );

    if (spOnly) {
      secondTl.to(
        $(".about__image__contents", secondAboutEl),
        {
          opacity: 1,
          duration: 1.5,
        },
        "-=0.5"
      );
      secondTl.to(
        $$(".about__image__shadow", secondAboutEl),
        {
          opacity: 1,
          duration: 1,
        },
        "-=0.5"
      );
    }

    ScrollTrigger.create({
      trigger: secondAboutEl,
      animation: secondTl,
      start: "top center",
    });
  }
  favoriteDirection() {
    const slide = new Splide(".splide", {
      perPage: 5,
      gap: "16rem",
      arrows: false,
      type: "loop",
      drag: true,
      snap: true,
      perMove: 1,
      breakpoints: {
        750: {
          perPage: "auto",
          perMove: 1,
          focus: "left",
          gap: "14rem",
          padding: {
            left: "38rem",
            right: "38rem",
          },
        },
      },
    });
    slide.mount();

    $(".favorite-slider__button--prev").addEventListener("click", () => {
      slide.go("<");
    });
    $(".favorite-slider__button--next").addEventListener("click", () => {
      slide.go(">");
    });
  }
  plansDirection() {
    function setHeight() {
      $$(".plans__flow__item").forEach((item, index) => {
        gsap.set($$(".plans__flow__graph-item")[index], {
          "--height": item.clientHeight + "px",
        });
      });
      requestAnimationFrame(setHeight);
    }
    requestAnimationFrame(setHeight);
    setTimeout(() => {
      cancelAnimationFrame(setHeight);
    }, 2000);

    // 背景の色を変える
    // const white = "#ffffff";
    // const gray = "#F2F2F2";
    // gsap.set(".plans", {
    //   backgroundColor: gray,
    // });

    // const changeWhite = () => {
    //   gsap.to(".plans, .why", {
    //     // duration: 1,
    //     backgroundColor: white,
    //   });
    // };
    // const changeGray = () => {
    //   gsap.set(".plans, .why", {
    //     // duration: 1,
    //     backgroundColor: gray,
    //   });
    // };

    // ScrollTrigger.create({
    //   trigger: ".plans",
    //   start: "top center",
    //   // markers: true,
    //   onEnter: () => {
    //     console.log("enter");
    //     changeWhite();
    //   },

    //   onLeaveBack: () => {
    //     console.log("leaveBack");
    //     changeGray();
    //   },
    // });
  }
  galleryDirection() {
    // const white = "#ffffff";
    // const gray = "#F2F2F2";
    // gsap.set(".gallery", {
    //   backgroundColor: gray,
    // });
    // const changeWhite = () => {
    //   gsap.to(".price, .gallery", {
    //     duration: 1,
    //     backgroundColor: white,
    //   });
    // };
    // const changeGray = () => {
    //   gsap.to(".price, .gallery", {
    //     duration: 1,
    //     backgroundColor: gray,
    //   });
    // };
    // ScrollTrigger.create({
    //   trigger: ".gallery",
    //   start: "top center",
    //   onEnter: () => {
    //     console.log("enter");
    //     changeWhite();
    //   },
    //   onLeaveBack: () => {
    //     console.log("leaveBack");
    //     changeGray();
    //   },
    // });
  }
  faqDirection() {
    $$(".faq__item").forEach((item, index) => {
      $(".faq__item__head", item).addEventListener("click", () => {
        gsap.to($(".faq__item__answer", item), {
          height: $(".faq__item__answer", item).clientHeight === 0 ? "auto" : 0,
          duration: 0.6,
          ease: "power2.inOut",
        });

        gsap.to($("path", item), {
          rotate: $(".faq__item__answer", item).clientHeight === 0 ? "90deg" : "0deg",
          transformOrigin: "center",
          duration: 0.5,
        });
      });
    });
  }
}
