import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { GSDevTools } from "gsap/GSDevTools";
// import { SplitText } from "gsap/SplitText";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { MotionPathHelper } from "gsap/MotionPathHelper";
// import { CustomEase } from "gsap/CustomEase";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import { $, $$, cssVal, scaleClip, spOnly } from "./_global.js";
gsap.registerPlugin(
  ScrollTrigger,
  // DrawSVGPlugin,
  // ScrollToPlugin,
  // GSDevTools,
  // SplitText,
  // MotionPathPlugin,
  // MotionPathHelper,
  // CustomEase
  ScrollSmoother
);

export default class App {
  constructor() {}
  init() {
    ScrollTrigger.config({
      ignoreMobileResize: true,
    });
    this.lineOver();
    this.pageScrollTo();
    this.scrollSettings();
    this.toggleMenu();
    this.changePinkText();
  }
  lineOver() {
    $$("[line-over]").forEach((el) => {
      const isLink = el.tagName === "A" || el.tagName === "BUTTON";
      // elの直下にspanを作成
      const span = document.createElement("span");
      span.classList.add("line-over__span");
      el.appendChild(span);
      if (!isLink) {
        el = el.closest("a, button");
        $$("*", el).forEach((child) => {
          child.style.pointerEvents = "none";
        });
      }

      el.addEventListener("mouseenter", () => {
        gsap.to($(".line-over__span", el), {
          transformOrigin: "left",
          scaleX: 1,
          duration: 0.7,
          ease: "power2.out",
        });
      });

      el.addEventListener("mouseleave", () => {
        gsap.to($(".line-over__span", el), {
          transformOrigin: "right",
          scaleX: 0,
          duration: 0.7,
          ease: "power2.inOut",
        });
      });

      // const target = el.closest("a, button") || el;
      // target.addEventListener("mouseenter", () => {
      //   gsap.to(el, {
      //     scaleX: 1,
      //     duration: 0.3,
      //   });
      // })
    });
  }
  pageScrollTo() {
    $$("[to]").forEach((item) => {
      const target = $(item.getAttribute("to"));
      item.addEventListener("click", () => {
        gsap.to(window, {
          scrollTo: target,
          duration: 1,
        });
      });
    });
  }
  scrollSettings() {
    if (spOnly) return;
    window.scrollSmootherBody = ScrollSmoother.create({
      ease: "expo.out",
      wrapper: ".l-content",
      content: ".p-index",
      smooth: 0.3,
      effects: true,
      smoothTouch: 0.1,
      normalizeScroll: false,
      ignoreMobileResize: true,
    });
    ScrollTrigger.refresh();
  }
  toggleMenu() {
    $$(".base-header__menu-button, .base-header__menu__item").forEach((el) => {
      el.addEventListener("click", () => {
        $(".base-header").toggleAttribute("menu");
        const tl = gsap.timeline();
        const fadeEls =
          ".base-header__menu__title, .base-header__menu__sub-title, .base-header__menu__copy";
        if ($(".base-header").hasAttribute("menu")) {
          tl.set(fadeEls, {
            opacity: 0,
          });
          tl.to(
            ".icon_menu",
            {
              y: "120%",
              duration: 0.6,
            },
            "menu"
          );
          tl.to(
            ".icon_close",
            {
              y: 0,
              duration: 0.6,
            },
            "menu"
          );
          tl.to(
            ".base-header__menu",
            {
              y: 0,
              duration: 1.2,
              ease: "power4.out",
            },
            "menu"
          );
          tl.to(
            fadeEls,
            {
              opacity: 1,
              duration: 0.6,
              stagger: 0.1,
            },
            "-=0.6"
          );
        } else {
          tl.to(
            ".icon_menu",
            {
              y: "0%",
              duration: 0.6,
            },
            "menu"
          );
          tl.to(
            ".icon_close",
            {
              y: "-120%",
              duration: 0.6,
            },
            "menu"
          );
          tl.to(
            ".base-header__menu",
            {
              y: "-100%",
              duration: 1,
              ease: "power3.out",
            },
            "menu"
          );
        }
      });
    });
  }
  changePinkText() {
    const pink = cssVal({
      name: "--color-pink-100",
    });

    const white = cssVal({
      name: "--color-white-100",
    });

    $$("[pink-text]").forEach((el) => {
      gsap.set(el, {
        color: white,
        opacity: 0.3,
      });

      const delay = Math.random() * 0.3;
      gsap.to(el, {
        color: pink,
        opacity: 1,
        duration: 0.5 + delay,
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
        },
      });
    });
  }
}
